/* ----------------------------------------------------------
  ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  MODALS ACTIONS
----------------------------------------------------------- */

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

/* ----------------------------------------------------------
  MODALS LISTS
----------------------------------------------------------- */

export const GET_TITLESTYPES_REQUEST = "GET_TITLESTYPES_REQUEST";
export const GET_TITLESTYPES_SUCCESS = "GET_TITLESTYPES_SUCCESS";

export const GET_INVOICESTYPES_REQUEST = "GET_INVOICESTYPES_REQUEST";
export const GET_INVOICESTYPES_SUCCESS = "GET_INVOICESTYPES_SUCCESS";

export const GET_ACCOUNTGROUPS_REQUEST = "GET_ACCOUNTGROUPS_REQUEST";
export const GET_ACCOUNTGROUPS_SUCCESS = "GET_ACCOUNTGROUPS_SUCCESS";

export const GET_PAYMENTCHOICES_REQUEST = "GET_PAYMENTCHOICES_REQUEST";
export const GET_PAYMENTCHOICES_SUCCESS = "GET_PAYMENTCHOICES_SUCCESS";

export const GET_JOBTITLE_REQUEST = "GET_JOBTITLE_REQUEST";
export const GET_JOBTITLE_SUCCESS = "GET_JOBTITLE_SUCCESS";
export const GET_TR_JOBTITLE_REQUEST = "GET_TR_JOBTITLE_REQUEST";
export const GET_TR_JOBTITLE_SUCCESS = "GET_TR_JOBTITLE_SUCCESS";

export const GET_MISSION_EXPERIENCES_REQUEST =
  "GET_MISSION_EXPERIENCES_REQUEST";
export const GET_MISSION_EXPERIENCES_SUCCESS =
  "GET_MISSION_EXPERIENCES_SUCCESS";

export const GET_MISSION_REASONS_REQUEST = "GET_MISSION_REASONS_REQUEST";
export const GET_MISSION_REASONS_SUCCESS = "GET_MISSION_REASONS_SUCCESS";

export const GET_DRIVER_LICENSE_REQUEST = "GET_DRIVER_LICENSE_REQUEST";
export const GET_DRIVER_LICENSE_SUCCESS = "GET_DRIVER_LICENSE_SUCCESS";

export const GET_MISSION_REMUNERATION_REQUEST =
  "GET_MISSION_REMUNERATION_REQUEST";
export const GET_MISSION_REMUNERATION_SUCCESS =
  "GET_MISSION_REMUNERATION_SUCCESS";

export const GET_MISSION_SALARIES_REQUEST = "GET_MISSION_SALARIES_REQUEST";
export const GET_MISSION_SALARIES_SUCCESS = "GET_MISSION_SALARIES_SUCCESS";
export const GET_MISSION_SALARIES_FAILURE = "GET_MISSION_SALARIES_FAILURE";

export const GET_EDUCATION_LEVEL_REQUEST = "GET_EDUCATION_LEVEL_REQUEST";
export const GET_EDUCATION_LEVEL_SUCCESS = "GET_EDUCATION_LEVEL_SUCCESS";

export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";

export const GET_JOB_SKILLS_REQUEST = "GET_JOB_SKILLS_REQUEST";
export const GET_JOB_SKILLS_SUCCESS = "GET_JOB_SKILLS_SUCCESS";

export const GET_JOB_TAGS_REQUEST = "GET_JOB_TAGS_REQUEST";
export const GET_JOB_TAGS_SUCCESS = "GET_JOB_TAGS_SUCCESS";

export const GET_APE_REQUEST = "GET_APE_REQUEST";
export const GET_APE_SUCCESS = "GET_APE_SUCCESS";

export const CREATE_JOB_TAGS_REQUEST = "CREATE_JOB_TAGS_REQUEST";
export const CREATE_JOB_TAGS_SUCCESS = "CREATE_JOB_TAGS_SUCCESS";

export const CREATE_JOB_SKILLS_REQUEST = "CREATE_JOB_SKILLS_REQUEST";
export const CREATE_JOB_SKILLS_SUCCESS = "CREATE_JOB_SKILLS_SUCCESS";

export const GET_EQUIPMENT_REQUEST = "GET_EQUIPMENT_REQUEST";
export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS";

export const GET_CONTRACT_TYPE_REQUEST = "GET_CONTRACT_TYPE_REQUEST";
export const GET_CONTRACT_TYPE_SUCCESS = "GET_CONTRACT_TYPE_SUCCESS";

/* ----------------------------------------------------------
  /MODALS LISTS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  CLIENT ACTIONS
----------------------------------------------------------- */

export const CLIENT_LOGIN_REQUEST = "CLIENT_LOGIN_REQUEST";
export const CLIENT_LOGIN_SUCCESS = "CLIENT_LOGIN_SUCCESS";
export const CLIENT_LOGIN_FAILURE = "CLIENT_LOGIN_FAILURE";

export const CLIENT_REGISTER_REQUEST = "CLIENT_REGISTER_REQUEST";
export const CLIENT_REGISTER_SUCCESS = "CLIENT_REGISTER_SUCCESS";
export const CLIENT_REGISTER_FAILURE = "CLIENT_REGISTER_FAILURE";

export const CLIENT_LOGOUT_REQUEST = "CLIENT_LOGOUT_REQUEST";
export const CLIENT_LOGOUT_SUCCESS = "CLIENT_LOGOUT_SUCCESS";
export const CLIENT_LOGOUT_FAILURE = "CLIENT_LOGOUT_FAILURE";

export const CLIENT_USER__REQUEST = "CLIENT_USER__REQUEST";
export const CLIENT_USER__SUCCESS = "CLIENT_USER__SUCCESS";
export const CLIENT_USER__FAILURE = "CLIENT_USER__FAILURE";

export const CLIENT_USER_REQUESTED = "CLIENT_USER_REQUESTED";
export const CLIENT_USER_SUCCESS = "CLIENT_USER_SUCCESS";

/* ----------------------------------------------------------
  /CLIENT ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  USER ACTIONS
----------------------------------------------------------- */

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_USER_BY_TOKEN_REQUEST = "GET_USER_BY_TOKEN_REQUEST";
export const GET_USER_BY_TOKEN_SUCCESS = "GET_USER_BY_TOKEN_SUCCESS";
export const GET_USER_BY_TOKEN_FAILURE = "GET_USER_BY_TOKEN_FAILURE";

/* ----------------------------------------------------------
  /USER ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  CONTACTS ACTIONS
----------------------------------------------------------- */
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";

export const INVITE_CONTACT_REQUEST = "INVITE_CONTACT_REQUEST";
export const INVITE_CONTACT_SUCCESS = "INVITE_CONTACT_SUCCESS";
export const INVITE_CONTACT_FAILURE = "INVITE_CONTACT_FAILURE";

export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";

export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILURE = "UPDATE_CONTACT_FAILURE";

export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

export const DELETE_MY_CONTACT_REQUEST = "DELETE_MY_CONTACT_REQUEST";
export const DELETE_MY_CONTACT_SUCCESS = "DELETE_MY_CONTACT_SUCCESS";
export const DELETE_MY_CONTACT_FAILURE = "DELETE_MY_CONTACT_FAILURE";

/* ----------------------------------------------------------
  /CONTACTS ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  RECRUITER ACTIONS
----------------------------------------------------------- */
export const GET_RECRUITER_REQUEST = "GET_RECRUITER_REQUEST";
export const GET_RECRUITER_SUCCESS = "GET_RECRUITER_SUCCESS";
export const GET_RECRUITER_FAILURE = "GET_RECRUITER_FAILURE";

export const GET_BACKOFFICE_CONTRACT_LIST_REQUEST =
  "GET_BACKOFFICE_CONTRACT_LIST_REQUEST";
export const GET_BACKOFFICE_CONTRACT_LIST_SUCCESS =
  "GET_BACKOFFICE_CONTRACT_LIST_SUCCESS";
export const GET_BACKOFFICE_CONTRACT_LIST_FAILED =
  "GET_BACKOFFICE_CONTRACT_LIST_FAILED";

export const GET_BACKOFFICE_DASHBOARD_REQUEST =
  "GET_BACKOFFICE_DASHBOARD_REQUEST";
export const GET_BACKOFFICE_DASHBOARD_SUCCESS =
  "GET_BACKOFFICE_DASHBOARD_SUCCESS";
export const GET_BACKOFFICE_DASHBOARD_FAILURE =
  "GET_BACKOFFICE_DASHBOARD_FAILURE";

export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";

export const SEND_TO_ANAEL_REQUEST = "SEND_TO_ANAEL_REQUEST";
export const SEND_TO_ANAEL_SUCCESS = "SEND_TO_ANAEL_SUCCESS";
export const SEND_TO_ANAEL_FAILURE = "SEND_TO_ANAEL_FAILURE";

/* ----------------------------------------------------------
  /RECRUITER ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  COMPANIES ACTIONS
----------------------------------------------------------- */

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const SWITCH_COMPANY_REQUEST = "SWITCH_COMPANY_REQUEST";
export const SWITCH_COMPANY_SUCCESS = "SWITCH_COMPANY_SUCCESS";
export const SWITCH_COMPANY_FAILURE = "SWITCH_COMPANY_FAILURE";

export const CHECK_COMPANY_REQUEST = "CHECK_COMPANY_REQUEST";
export const CHECK_COMPANY_SUCCESS = "CHECK_COMPANY_SUCCESS";
export const CHECK_COMPANY_FAILURE = "CHECK_COMPANY_FAILURE";

/* ----------------------------------------------------------
  /COMPANIES ACTIONS
----------------------------------------------------------- */
/* ----------------------------------------------------------
  DASHBOARD ACTIONS
----------------------------------------------------------- */

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export const GET_USER_START_GUIDE = "GET_USER_START_GUIDE";

/* ----------------------------------------------------------
  /DASHBOARD ACTIONS
----
/* ----------------------------------------------------------
  VACANCIES ACTIONS
----------------------------------------------------------- */

export const GET_USER_VACANCIES_REQUEST = "GET_USER_VACANCIES_REQUEST";
export const GET_USER_VACANCIES_SUCCESS = "GET_USER_VACANCIES_SUCCESS";
export const GET_USER_VACANCIES_FAILURE = "GET_USER_VACANCIES_FAILURE";

export const SEARCH_MISSION_REQUEST = "SEARCH_MISSION_REQUEST";
export const SEARCH_MISSION_SUCCESS = "SEARCH_MISSION_SUCCESS";
export const SEARCH_MISSION_FAILURE = "SEARCH_MISSION_FAILURE";

export const SEARCH_MISSION_TEMPLATE_REQUEST =
  "SEARCH_MISSION_TEMPLATE_REQUEST";
export const SEARCH_MISSION_TEMPLATE_SUCCESS =
  "SEARCH_MISSION_TEMPLATE_SUCCESS";
export const SEARCH_MISSION_TEMPLATE_FAILURE =
  "SEARCH_MISSION_TEMPLATE_FAILURE";

export const GET_STORED_REQUEST = "GET_STORED_REQUEST";
/* ----------------------------------------------------------
  /VACANCIES ACTIONS
----
/* ----------------------------------------------------------
  /ACTIONS
----------------------------------------------------------- */

export const DELETE_ERRORS_REQUEST = "DELETE_ERRORS_REQUEST";

/* ----------------------------------------------------------
  VACANCIES ACTIONS
----------------------------------------------------------- */

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

/* ----------------------------------------------------------
  /VACANCIES ACTIONS
----------------------------------------------------------- */
/* ----------------------------------------------------------
  VACANCIES ACTIONS
----------------------------------------------------------- */

export const CREATE_VACANCY_REQUEST = "CREATE_VACANCY_REQUEST";
export const CREATE_VACANCY_SUCCESS = "CREATE_VACANCY_SUCCESS";
export const CREATE_VACANCY_FAILURE = "CREATE_VACANCY_FAILURE";

export const UPDATE_VACANCY_REQUEST = "UPDATE_VACANCY_REQUEST";
export const UPDATE_VACANCY_SUCCESS = "UPDATE_VACANCY_SUCCESS";
export const UPDATE_VACANCY_FAILURE = "UPDATE_VACANCY_FAILURE";

export const UPDATE_VACANCY_TEMPLATE_REQUEST =
  "UPDATE_VACANCY_TEMPLATE_REQUEST";
export const UPDATE_VACANCY_TEMPLATE_SUCCESS =
  "UPDATE_VACANCY_TEMPLATE_SUCCESS";
export const UPDATE_VACANCY_TEMPLATE_FAILURE =
  "UPDATE_VACANCY_TEMPLATE_FAILURE";

export const VALIDATE_VACANCY_REQUEST = "VALIDATE_VACANCY_REQUEST";
export const VALIDATE_VACANCY_SUCCESS = "VALIDATE_VACANCY_SUCCESS";
export const VALIDATE_VACANCY_FAILURE = "VALIDATE_VACANCY_FAILURE";

export const GET_ACCOUNT_VACANCY_REQUEST = "GET_ACCOUNT_VACANCY_REQUEST";
export const GET_ACCOUNT_VACANCY_SUCCESS = "GET_ACCOUNT_VACANCY_SUCCESS";
export const GET_ACCOUNT_VACANCY_FAILURE = "GET_ACCOUNT_VACANCY_FAILURE";

export const GET_VACANCY_REQUEST = "GET_VACANCY_REQUEST";
export const GET_VACANCY_SUCCESS = "GET_VACANCY_SUCCESS";
export const GET_VACANCY_FAILURE = "GET_VACANCY_FAILURE";
export const GET_VACANCY_DELETE = "GET_VACANCY_DELETE";

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

export const GET_ACCOUNT_TEMPLATE_REQUEST = "GET_ACCOUNT_TEMPLATE_REQUEST";
export const GET_ACCOUNT_TEMPLATE_SUCCESS = "GET_ACCOUNT_TEMPLATE_SUCCESS";
export const GET_ACCOUNT_TEMPLATE_FAILURE = "GET_ACCOUNT_TEMPLATE_FAILURE";

export const GET_USER_TEMPLATE_REQUEST = "GET_USER_TEMPLATE_REQUEST";
export const GET_USER_TEMPLATE_SUCCESS = "GET_USER_TEMPLATE_SUCCESS";
export const GET_USER_TEMPLATE_FAILURE = "GET_USER_TEMPLATE_FAILURE";

export const CREATE_TEMPLATE_REQUEST = "CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAILURE = "CREATE_TEMPLATE_FAILURE";

export const GET_USER_VACANCY_REQUEST = "GET_USER_VACANCY_REQUEST";
export const GET_USER_VACANCY_SUCCESS = "GET_USER_VACANCY_SUCCESS";
export const GET_USER_VACANCY_FAILURE = "GET_USER_VACANCY_FAILURE";

export const SET_CURRENT_TEMPLATE_REQUEST = "SET_CURRENT_TEMPLATE_REQUEST";
export const SET_CURRENT_TEMPLATE_SUCCESS = "SET_CURRENT_TEMPLATE_SUCCESS";
export const SET_CURRENT_TEMPLATE_FAILURE = "SET_CURRENT_TEMPLATE_FAILURE";

export const DELETE_CURRENT_TEMPLATE_REQUEST = "SET_CURRENT_TEMPLATE_REQUEST";

export const SET_CURRENT_DUPLICATE_REQUEST = "SET_CURRENT_DUPLICATE_REQUEST";
export const DELETE_CURRENT_DUPLICATE_REQUEST =
  "DELETE_CURRENT_DUPLICATE_REQUEST";

export const DELETE_MISSION_TEMPLATE_REQUEST =
  "DELETE_MISSION_TEMPLATE_REQUEST";
export const DELETE_MISSION_TEMPLATE_SUCCESS =
  "DELETE_MISSION_TEMPLATE_SUCCESS";
export const DELETE_MISSION_TEMPLATE_FAILURE =
  "DELETE_MISSION_TEMPLATE_FAILURE";

export const DELETE_MISSION_REQUEST = "DELETE_MISSION_REQUEST";
export const DELETE_MISSION_SUCCESS = "DELETE_MISSION_SUCCESS";
export const DELETE_MISSION_FAILURE = "DELETE_MISSION_FAILURE";

export const RESET_MISSION_INDICATOR_REQUEST =
  "RESET_MISSION_INDICATOR_REQUEST";

export const RESET_MISSION_REQUEST = "RESET_MISSION_REQUEST";
/* ----------------------------------------------------------
  /VACANCIES ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  APPLICANTS ACTIONS
----------------------------------------------------------- */
export const COUNT_MATCHING_REQUEST = "COUNT_MATCHING_REQUEST";
export const COUNT_MATCHING_SUCCESS = "COUNT_MATCHING_SUCCESS";
export const COUNT_MATCHING_FAILURE = "COUNT_MATCHING_FAILURE";

export const RESET_MATCHING_REQUEST = "RESET_MATCHING_REQUEST";

export const GET_MATCHING_REQUEST = "GET_MATCHING_REQUEST";
export const GET_MATCHING_SUCCESS = "GET_MATCHING_SUCCESS";
export const GET_MATCHING_FAILURE = "GET_MATCHING_FAILURE";

export const APPROVE_BY_CUSTOMER_REQUEST = "APPROVE_BY_CUSTOMER_REQUEST";
export const APPROVE_BY_CUSTOMER_SUCCESS = "APPROVE_BY_CUSTOMER_SUCCESS";
export const APPROVE_BY_CUSTOMER_FAILURE = "APPROVE_BY_CUSTOMER_FAILURE";

export const APPROVE_BY_APPLICANT_REQUEST = "APPROVE_BY_APPLICANT_REQUEST";
export const APPROVE_BY_APPLICANT_SUCCESS = "APPROVE_BY_APPLICANT_SUCCESS";
export const APPROVE_BY_APPLICANT_FAILURE = "APPROVE_BY_APPLICANT_FAILURE";

export const DECLINE_MATCHING_REQUEST = "DECLINE_MATCHING_REQUEST";
export const DECLINE_MATCHING_SUCCESS = "DECLINE_MATCHING_SUCCESS";
export const DECLINE_MATCHING_FAILURE = "DECLINE_MATCHING_FAILURE";

export const DECLINE_BY_CUSTOMER_REQUEST = "DECLINE_BY_CUSTOMER_REQUEST";
export const DECLINE_BY_CUSTOMER_SUCCESS = "DECLINE_BY_CUSTOMER_SUCCESS";
export const DECLINE_BY_CUSTOMER_FAILURE = "DECLINE_BY_CUSTOMER_FAILURE";

export const DELETE_APPLICATION_REQUEST = "DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILURE = "DELETE_APPLICATION_FAILURE";

export const DECLINE_BY_APPLICANT_REQUEST = "DECLINE_BY_APPLICANT_REQUEST";
export const DECLINE_BY_APPLICANT_SUCCESS = "DECLINE_BY_APPLICANT_SUCCESS";
export const DECLINE_BY_APPLICANT_FAILURE = "DECLINE_BY_APPLICANT_FAILURE";

export const GET_FORMATTED_CV_REQUEST = "GET_FORMATTED_CV_REQUEST";
export const GET_FORMATTED_CV_SUCCESS = "GET_FORMATTED_CV_SUCCESS";
export const GET_FORMATTED_CV_FAILURE = "GET_FORMATTED_CV_FAILURE";

export const CLEAR_FORMATTED_CV_REQUEST = "CLEAR_FORMATTED_CV_REQUEST";

export const UPDATE_APPLICANT_REQUEST = "UPDATE_APPLICANT_REQUEST";
export const UPDATE_APPLICANT_SUCCESS = "UPDATE_APPLICANT_SUCCESS";
export const UPDATE_APPLICANT_FAILURE = "UPDATE_APPLICANT_FAILURE";

export const GET_APPLICANT_ID_REQUEST = "GET_APPLICANT_ID_REQUEST";
export const GET_APPLICANT_ID_SUCCESS = "GET_APPLICANT_ID_SUCCESS";
export const UPDATE_APPLICANT_ID_FAILURE = "UPDATE_APPLICANT_ID_FAILURE";

export const DELETE_APPLICANT_REQUEST = "DELETE_APPLICANT_REQUEST";
export const DELETE_APPLICANT_SUCCESS = "DELETE_APPLICANT_SUCCESS";
export const DELETE_APPLICANT_FAILURE = "DELETE_APPLICANT_FAILURE";

/* ----------------------------------------------------------
  /APPLICANTS ACTIONS
----------------------------------------------------------- */
export const GET_INTERIMAIRE_REQUEST = "GET_INTERIMAIRE_REQUEST";
export const GET_INTERIMAIRE_SUCCESS = "GET_INTERIMAIRE_SUCCESS";
export const GET_INTERIMAIRE_FAILURE = "GET_INTERIMAIRE_FAILURE";

export const DELETE_INTERIMAIRE_REQUEST = "DELETE_INTERIMAIRE_REQUEST";
export const DELETE_INTERIMAIRE_SUCCESS = "DELETE_INTERIMAIRE_SUCCESS";
export const DELETE_INTERIMAIRE_FAILURE = "DELETE_INTERIMAIRE_FAILURE";

export const UPDATE_INTERIMAIRE_REQUEST = "UPDATE_INTERIMAIRE_REQUEST";
export const UPDATE_INTERIMAIRE_SUCCESS = "UPDATE_INTERIMAIRE_SUCCESS";
export const UPDATE_INTERIMAIRE_FAILURE = "UPDATE_INTERIMAIRE_FAILURE";

export const PARSE_RESUME_REQUEST = "PARSE_RESUME_REQUEST";
export const PARSE_RESUME_SUCCESS = "PARSE_RESUME_SUCCESS";
export const PARSE_RESUME_FAILURE = "PARSE_RESUME_FAILURE";

export const INTERIMAIRE_REGISTER_REQUEST = "INTERIMAIRE_REGISTER_REQUEST";
export const INTERIMAIRE_REGISTER_SUCCESS = "INTERIMAIRE_REGISTER_SUCCESS";
export const INTERIMAIRE_REGISTER_FAILURE = "INTERIMAIRE_REGISTER_FAILURE";

export const GET_DASHBOARD_INTERIMAIRE_REQUEST =
  "GET_DASHBOARD_INTERIMAIRE_REQUEST";
export const GET_DASHBOARD_INTERIMAIRE_SUCCESS =
  "GET_DASHBOARD_INTERIMAIRE_SUCCESS";
export const GET_DASHBOARD_INTERIMAIRE_FAILURE =
  "GET_DASHBOARD_INTERIMAIRE_FAILURE";

export const SEARCH_INTERIMAIRE_REQUEST = "SEARCH_INTERIMAIRE_REQUEST";
export const SEARCH_INTERIMAIRE_SUCCESS = "SEARCH_INTERIMAIRE_SUCCESS";
export const SEARCH_INTERIMAIRE_FAILURE = "SEARCH_INTERIMAIRE_FAILURE";

export const GET_INTERIMAIRE_BYID_REQUEST = "GET_INTERIMAIRE_BYID_REQUEST";
export const GET_INTERIMAIRE_BYID_SUCCESS = "GET_INTERIMAIRE_BYID_SUCCESS";
export const GET_INTERIMAIRE_BYID_FAILURE = "GET_INTERIMAIRE_BYID_FAILURE";

export const CANCEL_EDIT = "CANCEL_EDIT";

/* ----------------------------------------------------------
  /NOTIFICATIONS ACTIONS
----------------------------------------------------------- */

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIF_READ = "SET_NOTIF_READ";
export const PUSH_NEW_NOTIF = "PUSH_NEW_NOTIF";

/* ----------------------------------------------------------
  /NOTIFICATIONS ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  /MISSIONS ACTIONS
----------------------------------------------------------- */

export const GET_EXTENSIONS_REQUEST = "GET_EXTENSIONS_REQUEST";
export const GET_EXTENSIONS_SUCCESS = "GET_EXTENSIONS_SUCCESS";
export const GET_EXTENSIONS_FAILURE = "GET_EXTENSIONS_FAILURE";

export const PUT_EXTENSIONS_REQUEST = "PUT_EXTENSIONS_REQUEST";
export const PUT_EXTENSIONS_SUCCESS = "PUT_EXTENSIONS_SUCCESS";
export const PUT_EXTENSIONS_FAILURE = "PUT_EXTENSIONS_FAILURE";

export const ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_FAILURE = "ADD_FAVORITE_FAILURE";

export const REMOVE_FAVORITE_REQUEST = "REMOVE_FAVORITE_REQUEST";
export const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS";
export const REMOVE_FAVORITE_FAILURE = "REMOVE_FAVORITE_FAILURE";

/* ----------------------------------------------------------
  /MISSIONS ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  /MAIL TEMPLATES ACTIONS
----------------------------------------------------------- */

export const GET_MAIL_TEMPLATES_REQUEST = "GET_MAIL_TEMPLATES_REQUEST";
export const GET_MAIL_TEMPLATES_SUCCESS = "GET_MAIL_TEMPLATES_SUCCESS";
export const GET_MAIL_TEMPLATES_FAILURE = "GET_MAIL_TEMPLATES_FAILURE";

export const GET_MAIL_TEMPLATE_CATEGORIES_REQUEST =
  "GET_MAIL_TEMPLATE_CATEGORIES_REQUEST";
export const GET_MAIL_TEMPLATE_CATEGORIES_SUCCESS =
  "GET_MAIL_TEMPLATE_CATEGORIES_SUCCESS";
export const GET_MAIL_TEMPLATE_CATEGORIES_FAILURE =
  "GET_MAIL_TEMPLATE_CATEGORIES_FAILURE";

export const SET_MAIL_TEMPLATE = "SET_MAIL_TEMPLATE";

export const PUT_MAIL_TEMPLATE_REQUEST = "PUT_MAIL_TEMPLATE_REQUEST";
export const PUT_MAIL_TEMPLATE_SUCCESS = "PUT_MAIL_TEMPLATE_SUCCESS";
export const PUT_MAIL_TEMPLATE_FAILURE = "PUT_MAIL_TEMPLATE_FAILURE";

/* ----------------------------------------------------------
  /MAIL TEMPLATES ACTIONS
----------------------------------------------------------- */

/* ----------------------------------------------------------
  /COMMERCIAL AGREEMENTS ACTIONS
----------------------------------------------------------- */

export const GET_COMMERCIAL_AGREEMENTS_REQUEST =
  "GET_COMMERCIAL_AGREEMENTS_REQUEST";
export const GET_COMMERCIAL_AGREEMENTS_SUCCESS =
  "GET_COMMERCIAL_AGREEMENTS_SUCCESS";
export const GET_COMMERCIAL_AGREEMENTS_FAILURE =
  "GET_COMMERCIAL_AGREEMENTS_FAILURE";

export const SET_COMMERCIAL_AGREEMENT = "SET_COMMERCIAL_AGREEMENT";

export const PUT_COMMERCIAL_AGREEMENT_REQUEST =
  "PUT_COMMERCIAL_AGREEMENT_REQUEST";
export const PUT_COMMERCIAL_AGREEMENT_SUCCESS =
  "PUT_COMMERCIAL_AGREEMENT_SUCCESS";
export const PUT_COMMERCIAL_AGREEMENT_FAILURE =
  "PUT_COMMERCIAL_AGREEMENT_FAILURE";

export const POST_COMMERCIAL_AGREEMENT_REQUEST =
  "POST_COMMERCIAL_AGREEMENT_REQUEST";
export const POST_COMMERCIAL_AGREEMENT_SUCCESS =
  "POST_COMMERCIAL_AGREEMENT_SUCCESS";
export const POST_COMMERCIAL_AGREEMENT_FAILURE =
  "POST_COMMERCIAL_AGREEMENT_FAILURE";

export const DELETE_COMMERCIAL_AGREEMENT_REQUEST =
  "DELETE_COMMERCIAL_AGREEMENT_REQUEST";
export const DELETE_COMMERCIAL_AGREEMENT_SUCCESS =
  "DELETE_COMMERCIAL_AGREEMENT_SUCCESS";
export const DELETE_COMMERCIAL_AGREEMENT_FAILURE =
  "DELETE_COMMERCIAL_AGREEMENT_FAILURE";

/* ----------------------------------------------------------
  /COMMERCIAL AGREEMENTS ACTIONS
----------------------------------------------------------- */
