export const USER_BY_MOBILE_REQUEST = "USER_BY_MOBILE_REQUEST";
export const USER_BY_MOBILE_SUCCESS = "USER_BY_MOBILE_SUCCESS";
export const USER_BY_MOBILE_FAILLED = "USER_BY_MOBILE_FAILLED";

export const GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP";

export const GET_USER_START_GUIDE = "GET_USER_START_GUIDE";

export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const REMOVE_EXPERIENCE = "REMOVE_EXPERIENCE";
export const EDIT_EXPERIENCE = "EDIT_EXPERIENCE";

export const UPDATE_INTERIMAIRE_IDENTITY_REQUEST =
  "UPDATE_INTERIMAIRE_IDENTITY_REQUEST";
export const UPDATE_INTERIMAIRE_IDENTITY_SUCCESS =
  "UPDATE_INTERIMAIRE_IDENTITY_SUCCESS";
export const UPDATE_INTERIMAIRE_IDENTITY_FAILLED =
  "UPDATE_INTERIMAIRE_IDENTITY_FAILLED";

export const CLEAR_ANIMATION_DURATION = "CLEAR_ANIMATION_DURATION";
export const SET_COUNT_MATCHING = "SET_COUNT_MATCHING";
export const INCREMENT_COUNT_APPLICATIONS = "INCREMENT_COUNT_APPLICATIONS";
export const INCREMENT_COUNT_PROPOSITIONS = "INCREMENT_COUNT_PROPOSITIONS";

export const GET_CONTRACT_LIST_REQUEST = "GET_CONTRACT_LIST_REQUEST";
export const GET_CONTRACT_LIST_SUCCESS = "GET_CONTRACT_LIST_SUCCESS";
export const GET_CONTRACT_LIST_FAILLED = "GET_CONTRACT_LIST_FAILLED";

export const GET_DOCUMENTS_LIST_REQUEST = "GET_DOCUMENTS_LIST_REQUEST";
export const GET_DOCUMENTS_LIST_SUCCESS = "GET_DOCUMENTS_LIST_SUCCESS";
export const GET_DOCUMENTS_LIST_FAILLED = "GET_DOCUMENTS_LIST_FAILLED";

export const REMOVE_ONE_DOCUMENT_SUCCESS = "REMOVE_ONE_DOCUMENT_SUCCESS";
export const CLEAR_STEP_FIVE_MODAL = "CLEAR_STEP_FIVE_MODAL";

export const SET_CONTRACTS_COUNT = "SET_CONTRACTS_COUNT";

export const GET_NATIONALITIES_LIST = "GET_NATIONALITY_LIST";
