export const joursFeriesFix = [
  "01-01",
  "05-01",
  "05-08",
  "05-13",
  "07-14",
  "08-15",
  "11-01",
  "11-11",
  "12-25"
];
export const joursFeriesFlexible = [
  "2021-04-05",
  "2021-05-24",
  "2022-04-18",
  "2022-06-06",
  "2023-04-10",
  "2023-05-29",
  "2024-04-01",
  "2024-05-20",
  "2025-04-21",
  "2025-06-09"
];
