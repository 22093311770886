import React from "react";

import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function DeleteExperienceModal({
  show,
  onHide,
  deleteExperience,
  row,
  onDeleteExperience,
  errorArray
}) {
  const confirmDelete = () => {
    const tempErrorArray = errorArray;
    const id = row.id ? row.id : row.id_temp;
    const index = tempErrorArray.indexOf(id);
    if (index > -1) {
      tempErrorArray.splice(index, 1);
      onDeleteExperience(tempErrorArray);
    }
    deleteExperience();
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="XP.DELETE.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          <FormattedMessage id="XP.DELETE.DESC" />
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light-primary btn-shadow font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <FormattedMessage id="BUTTON.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={() => confirmDelete(row)}
            className="btn btn-danger btn-shadow font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <FormattedMessage id="BUTTON.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
