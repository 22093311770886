export const userSelectorTypes = [
  { id: 2, value: "Admin" },
  { id: 3, value: "Utilisateur" }
];

export const userTypes = [
  { id: 1, value: "Super Admin" },
  { id: 2, value: "Admin" },
  { id: 3, value: "Utilisateur" }
];
