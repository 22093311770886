export const GET_INTERIMAIRES_LIST_REQUEST = "GET_INTERIMAIRES_LIST_REQUEST";
export const GET_INTERIMAIRES_LIST_SUCCESS = "GET_INTERIMAIRES_LIST_SUCCESS";
export const GET_INTERIMAIRES_LIST_FAILLED = "GET_INTERIMAIRES_LIST_FAILLED";

export const GET_ACTIVE_INTERIMAIRE_REQUEST = "GET_ACTIVE_INTERIMAIRE_REQUEST";
export const GET_ACTIVE_INTERIMAIRE_SUCCESS = "GET_ACTIVE_INTERIMAIRE_SUCCESS";
export const GET_ACTIVE_INTERIMAIRE_FAILLED = "GET_ACTIVE_INTERIMAIRE_FAILLED";

export const CLEAR_SELECTED_APPLICANT = "CLEAR_SELECTED_APPLICANT";
export const UPDATE_SELECTED_APPLICANT = "UPDATE_SELECTED_APPLICANT";

export const ADD_SELECTED_APPLICANT_EXPERIENCE =
  "ADD_SELECTED_APPLICANT_EXPERIENCE";
export const REMOVE_SELECTED_APPLICANT__EXPERIENCE =
  "REMOVE_SELECTED_APPLICANT__EXPERIENCE";
export const UPDATE_SELECTED_APPLICANT_IDENTITY_REQUEST =
  "UPDATE_SELECTED_APPLICANT_IDENTITY_REQUEST";
export const UPDATE_SELECTED_APPLICANT_IDENTITY_SUCCESS =
  "UPDATE_SELECTED_APPLICANT_IDENTITY_SUCCESS";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const REMOVE_ONE_SELECTED_APPLICANT_DOCUMENT_SUCCESS =
  "REMOVE_ONE_SELECTED_APPLICANT_DOCUMENT_SUCCESS";
export const CLEAR_SELECTED_APPLICANT_STEP_FIVE_MODAL =
  "CLEAR_SELECTED_APPLICANT_STEP_FIVE_MODAL";

export const SEARCH_SELECTED_APPLICANT_MISSIONS_REQUEST =
  "SEARCH_SELECTED_APPLICANT_MISSIONS_REQUEST";
export const SEARCH_SELECTED_APPLICANT_MISSIONS_SUCCESS =
  "SEARCH_SELECTED_APPLICANT_MISSIONS_SUCCESS";
export const SEARCH_SELECTED_APPLICANT_MISSIONS_FAILLED =
  "SEARCH_SELECTED_APPLICANT_MISSIONS_FAILLED";

export const GET_SELECTED_APPLICANT_EMAILS_REQUEST =
  "GET_SELECTED_APPLICANT_EMAILS_REQUEST";
export const GET_SELECTED_APPLICANT_EMAILS_SUCCESS =
  "GET_SELECTED_APPLICANT_EMAILS_SUCCESS";
export const GET_SELECTED_APPLICANT_EMAILS_FAILLED =
  "GET_SELECTED_APPLICANT_EMAILS_FAILLED";

export const SET_LATEST_CLIENT_EDITED = "SET_LATEST_CLIENT_EDITED";
export const CLEAR_LATEST_CLIENT_EDITED = "CLEAR_LATEST_CLIENT_EDITED";

export const SET_FILTER_COMPANY = "SET_FILTER_COMPANY";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const SET_FILTER_START_DATE = "SET_FILTER_START_DATE";
export const SET_FILTER_END_DATE = "SET_FILTER_END_DATE";
export const SET_FILTER_PAGE_SIZE = "SET_FILTER_PAGE_SIZE";
export const SET_FILTER_PAGE_NUMBER = "SET_FILTER_PAGE_NUMBER";

export const UPDATE_NEW_ACCOUNTS = "UPDATE_NEW_ACCOUNTS";
export const UPDATE_NEW_APPLICANTS = "UPDATE_NEW_APPLICANTS";
export const UPDATE_NUMBER_MONTHLY_CONTRACTS =
  "UPDATE_NUMBER_MONTHLY_CONTRACTS";
export const UPDATE_PROFILES_RATE = "UPDATE_PROFILES_RATE";
export const UPDATE_NUMBER_CONTRACTS = "UPDATE_NUMBER_CONTRACTS";
export const UPDATE_NUMBER_SIGNED_CONTRACTS = "UPDATE_NUMBER_SIGNED_CONTRACTS";
export const UPDATE_NUMBER_DELETED_APPLICANTS =
  "UPDATE_NUMBER_DELETED_APPLICANTS";
export const UPDATE_RATE_DELETED_APPLICANTS = "UPDATE_RATE_DELETED_APPLICANTS";
export const UPDATE_NUMBER_DELETED_ACCOUNTS = "UPDATE_NUMBER_DELETED_ACCOUNTS";
export const UPDATE_RATE_DELETED_ACCOUNTS = "UPDATE_RATE_DELETED_ACCOUNTS";
export const UPDATE_RATE_LOGIN_APPLICANTS = "UPDATE_RATE_LOGIN_APPLICANTS";
export const UPDATE_RATE_LOGIN_ACCOUNTS = "UPDATE_RATE_LOGIN_ACCOUNTS";

export const UPDATE_NBR_MISSIONS = "UPDATE_NBR_MISSIONS";
export const UPDATE_NBR_EXTENSIONS = "UPDATE_NBR_EXTENSIONS";
export const UPDATE_NBR_RH = "UPDATE_NBR_RH";
export const UPDATE_NBR_APPLICANTS = "UPDATE_NBR_APPLICANTS";
export const UPDATE_NBR_APPLICANTS_TO_CONTROL =
  "UPDATE_NBR_APPLICANTS_TO_CONTROL";
export const UPDATE_NBR_ACCOUNTS = "UPDATE_NBR_ACCOUNTS";
export const UPDATE_NBR_REFUS_ACCOUNTS = "UPDATE_NBR_REFUS_ACCOUNTS";
export const UPDATE_NBR_REFUS_APPLICANTS = "UPDATE_NBR_REFUS_APPLICANTS";
export const UPDATE_NBR_COMMERCIAL_AGREEMENTS =
  "UPDATE_NBR_COMMERCIAL_AGREEMENTS";
export const UPDATE_NBR_MSG_APPLICANTS = "UPDATE_NBR_MSG_APPLICANTS";
export const UPDATE_NBR_MSG_ACCOUNTS = "UPDATE_NBR_MSG_ACCOUNTS";
