export const fakeData = [
  {
    weekNumber: "15",
    applicant: "applicant",
    company: "entreprise",
    worksite: "chantier",
    contractNumber: "123",
    qualification: "testeur",
    startDate: "14/12/2019",
    endDate: "14/01/2022",
    status: 2
  },
  {
    weekNumber: "15",
    applicant: "applicant",
    company: "entreprise",
    worksite: "chantier",
    contractNumber: "123",
    qualification: "testeur",
    startDate: "14/12/2019",
    endDate: "14/01/2022",
    status: 1
  }
];

export const statusList = [
  {
    label: "A saisir",
    value: 0
  },
  {
    label: "A valider",
    value: 1
  },
  {
    label: "Validé",
    value: 2
  }
];

export const weekList = [
  {
    label: "N°1",
    value: 1
  },
  {
    label: "N°2",
    value: 2
  },
  {
    label: "N°3",
    value: 3
  },
  {
    label: "N°4",
    value: 4
  },
  {
    label: "N°5",
    value: 5
  },
  {
    label: "N°6",
    value: 6
  },
  {
    label: "N°7",
    value: 7
  },
  {
    label: "N°8",
    value: 8
  },
  {
    label: "N°9",
    value: 9
  },
  {
    label: "N°10",
    value: 10
  },
  {
    label: "N°11",
    value: 11
  },
  {
    label: "N°12",
    value: 12
  },
  {
    label: "N°13",
    value: 13
  },
  {
    label: "N°14",
    value: 14
  },
  {
    label: "N°15",
    value: 15
  },
  {
    label: "N°16",
    value: 16
  },
  {
    label: "N°17",
    value: 17
  },
  {
    label: "N°18",
    value: 18
  },
  {
    label: "N°19",
    value: 19
  },
  {
    label: "N°20",
    value: 20
  },
  {
    label: "N°21",
    value: 21
  },
  {
    label: "N°22",
    value: 22
  },
  {
    label: "N°23",
    value: 23
  },
  {
    label: "N°24",
    value: 24
  },
  {
    label: "N°25",
    value: 25
  },
  {
    label: "N°26",
    value: 26
  },
  {
    label: "N°27",
    value: 27
  },
  {
    label: "N°28",
    value: 28
  },
  {
    label: "N°29",
    value: 29
  },
  {
    label: "N°30",
    value: 30
  },
  {
    label: "N°31",
    value: 31
  },
  {
    label: "N°32",
    value: 32
  },
  {
    label: "N°33",
    value: 33
  },
  {
    label: "N°34",
    value: 34
  },
  {
    label: "N°35",
    value: 35
  },
  {
    label: "N°36",
    value: 36
  },
  {
    label: "N°37",
    value: 37
  },
  {
    label: "N°38",
    value: 38
  },
  {
    label: "N°39",
    value: 39
  },
  {
    label: "N°40",
    value: 40
  },
  {
    label: "N°41",
    value: 41
  },
  {
    label: "N°42",
    value: 42
  },
  {
    label: "N°43",
    value: 43
  },
  {
    label: "N°44",
    value: 44
  },
  {
    label: "N°45",
    value: 45
  },
  {
    label: "N°46",
    value: 46
  },
  {
    label: "N°47",
    value: 47
  },
  {
    label: "N°48",
    value: 48
  },
  {
    label: "N°49",
    value: 49
  },
  {
    label: "N°50",
    value: 50
  },
  {
    label: "N°51",
    value: 51
  },
  {
    label: "N°52",
    value: 52
  }
];
