import React, { useMemo } from "react";

import objectPath from "object-path";

// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";

// Import Layout components
//import { Aside } from "./aside/Aside";
import QuickUserClient from "./extras/offcanvas/QuickUserClient";
import QuickUserInterimaire from "./extras/offcanvas/QuickUserInterimaire";
import QuickUserBackOffice from "./extras/offcanvas/QuickUserBackOffice";
import { ScrollTop } from "./extras/ScrollTop";
import { Footer } from "./footer/Footer";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Header } from "./header/Header";
import { LayoutInit } from "./LayoutInit";
import { shallowEqual, useSelector } from "react-redux";
import { HeaderMobileInterimaire } from "./header-mobile/HeaderMobileInterimaire";

export function Layout({ children }) {
    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const { isInterimaire, isBackOffice } = useSelector(
        ({ auth }) => ({
            isInterimaire: auth.user != null ? auth.user.userType === 0 : false,
            isBackOffice: auth.user != null ? auth.user.userType === 2 : false,
        }),
        shallowEqual
    );

    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            {!isInterimaire ? <HeaderMobile /> : <HeaderMobileInterimaire />}
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {/* {layoutProps.asideDisplay && (<Aside />)} */}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header />
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} mb-5 d-flex flex-column flex-column-fluid`}
                        >
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer />
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {isInterimaire ? <QuickUserInterimaire /> : isBackOffice ? <QuickUserBackOffice /> : <QuickUserClient />}

            <ScrollTop />
            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
